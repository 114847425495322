import React, { Fragment } from "react";
import BlogList from "../components/BlogList";
import BlogDetail from "../components/BlogDetail";
import { Router } from "@reach/router";
import HeadSection from "../components/HeadSection";
import "../styles/main.scss";

const Blog = () => {
    return (<Fragment>
        <HeadSection />
        <Router>
            <BlogDetail path="/blog/:id" />
            <BlogList path="/blog" />
        </Router>
    </Fragment>);
};

export default Blog;